import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Button } from 'react-bootstrap'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { SlugLink } from '../components/utils'
//import Img from "gatsby-image"
//import { rhythm, scale } from "../utils/typography"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    //console.log(post)
    //console.log(next);
    let evt = undefined;
    if (post.frontmatter.name && post.frontmatter.name != "") {
      evt = {
        name: post.frontmatter.name,
        team1: post.frontmatter.team1,
        team2: post.frontmatter.team2,
        place: post.frontmatter.place
      };
    }
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={`${this.props.data.site.siteMetadata.siteUrl}${post.frontmatter.cover.publicURL}`}
          isBlogPost={true}
          datePublished={post.frontmatter.date}
          event={evt}
        />
        <div className="row justify-content-center">
    
        <div className="col-md-12 col-xl-9 psm-0">
        <article className="w-layout-container--narrow w-post-content">
        <header className="w-article-header">
          <h1 className="w-article-header__headline">{post.frontmatter.title}</h1>
          <div className="w-author__published ">
            By Barçamania <time>{post.frontmatter.date}</time>
          </div>
        </header>
            <section className="post-detail" dangerouslySetInnerHTML={{ __html: post.html }} />
            <div className="w-card__chips w-chips">
              {post.frontmatter.tags !== null ?
                post.frontmatter.tags.map(tag => (
                  <Link to={`/tags/${SlugLink(tag)}`} key={tag} className="label label--inline">{tag}</Link>
                ))
                :
                null
              }
            </div>
        </article>
      <div className="container blog-relate">
        <Row className="justify-content-center text-center">
            <div className="col-12">
              <h3 className="display-4">TIN MỚI</h3>
            </div>
            <Col xs={12} md={6} className="item-relate">
              {previous && (
                <div className="card">
                  <Link to={previous.fields.slug} title={previous.frontmatter.title}>
                    <img src={previous.frontmatter.cover.childImageSharp.fluid.src}
                                                                className="card-img-top" />
                  </Link>
                  <div className="card-body">
                    <h5 className="card-title">
                      <Link to={previous.fields.slug} title={previous.frontmatter.title}>{previous.frontmatter.title}</Link></h5>
                  </div>
                </div>
              )}

            </Col>
            <Col xs={12} md={6} className="item-relate">
              {next && (
                <div className="card">
                <Link to={next.fields.slug} title={next.frontmatter.title}>
                  <img src={next.frontmatter.cover.childImageSharp.fluid.src}
                                                              className="card-img-top" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">
                    <Link to={next.fields.slug} title={next.frontmatter.title}>{next.frontmatter.title}</Link>
                    </h5>
                </div>
              </div>
                
              )}
            </Col>
          </Row>
          </div>
          </div>
          </div>
      </Layout>
        )
      }
    }
    
    export default BlogPostTemplate
    
    export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
          site {
          siteMetadata {
          title
        siteUrl
      }
    }
    markdownRemark(fields: {slug: {eq: $slug } }) {
          id
      excerpt(pruneLength: 160)
        html
      frontmatter {
          title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        name
        team1
        team2
        place
        cover {
          publicURL
          childImageSharp {
          fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        }
      }
    }
  }
}
`
